export const resultData = [
    {
        title: '2022 Results',
        cover: '../images/courses-image/m1.png',
    },
    {
        title: '2021 Results',
        cover: '../images/courses-image/m1.png',
    },
    {
        title: '2020 Results',
        cover: '../images/courses-image/m1.png',
    },
]
import React from 'react'
import './AboutUS.css'
import { liveCountData } from './AboutUSData'

const LiveCount = () => {
    return(
        <>
            <section className='awrapper'>
                <div className='container grid'>
                    {liveCountData.map((val , index) => {
                        return (
                            <div key={index} className='box flex'>
                                <div className='img'>
                                    <img src={val.cover} alt='' />
                                </div>
                                <div className='text'>
                                    <h1>{val.data}</h1>
                                    <h3>{val.title}</h3>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default LiveCount
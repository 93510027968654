import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { INSTAGRAM_LINK , FACEBOOK_LINK , TWITTER_LINK , YOUTUBE_LINK , LINKEDIN_LINK } from '../SocialMedia/SocialMediaData'

const Head = () => {
    return (
        <>
            <section className='head' style={{ backgroundColor: "#2b2e74" }}>
                <div className='container flexSB'>
                    <div className='logo yellow-text'>
                        <Link to='/'>
                            <img src='./images/header-logo.png'  alt="header-logo"/>
                        </Link>
                    </div>

                    <div className='social'>
                        <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer"><i className='fab fa-facebook-f icon'></i></a>
                        <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer"><i className='fab fa-instagram icon'></i></a>
                        <a href={YOUTUBE_LINK} target="_blank" rel="noreferrer"><i className='fab fa-youtube icon'></i></a>
                        <a href={TWITTER_LINK} target="_blank" rel="noreferrer"><i className='fab fa-twitter icon'></i></a>
                        <a href={LINKEDIN_LINK} target="_blank" rel="noreferrer"><i className='fab fa-linkedin icon'></i></a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Head
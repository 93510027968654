import React from 'react'
import './Footer.css'
import { INSTAGRAM_LINK , FACEBOOK_LINK , TWITTER_LINK , YOUTUBE_LINK , LINKEDIN_LINK } from '../SocialMedia/SocialMediaData'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

const Footer = () => {
    return (
        <>
            <footer>
                <div className='container padding'>
                    <div className='box logo'>
                        <h1 className='yellow-text'>MEDIIT</h1>
                        <span>Asli Padhai</span>
                        <p>Synonymous to quality education and provides a learning environment that extends education beyond the confines of traditional classroom learning</p>

                        <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer"><i className='fab fa-facebook-f icon'></i></a>
                        <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer"><i className='fab fa-instagram icon'></i></a>
                        <a href={YOUTUBE_LINK} target="_blank" rel="noreferrer"><i className='fab fa-youtube icon'></i></a>
                        <a href={TWITTER_LINK} target="_blank" rel="noreferrer"><i className='fab fa-twitter icon'></i></a>
                        <a href={LINKEDIN_LINK} target="_blank" rel="noreferrer"><i className='fab fa-linkedin icon'></i></a>
                    </div>
                    <div className='box link'>
                        <h3>Explore</h3>
                        <ul>
                            <li>
                                <LinkS 
                                    activeClass="active"
                                    to="aboutus"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    About Us
                                </LinkS>
                            </li>
                            <li>
                                <LinkS 
                                    activeClass="active"
                                    to="courses"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Courses
                                </LinkS>
                            </li>
                            <li>
                                <LinkS 
                                    activeClass="active"
                                    to="testimonial"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Testimonial
                                </LinkS>
                            </li>
                            <li>
                                <LinkS 
                                    activeClass="active"
                                    to="admission"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Admission Process
                                </LinkS>
                            </li>
                        </ul>
                    </div>
                    <div className='box link'>
                        <h3>Quick Links</h3>
                        <ul>
                            <li>
                                <LinkR to='/team'>Team</LinkR>
                            </li>
                            <li>
                                <LinkR to='/contact-us'>Contact Us</LinkR>
                            </li>
                            <li>
                                <LinkR to='/results'>Results</LinkR>
                            </li>
                            <li>
                                <LinkR to='/careers'>Careers</LinkR>
                            </li>
                            <li>
                                <LinkR to='/scholarship'>Scholarship</LinkR>
                            </li>
                        </ul>
                    </div>
                    <div className='box last'>
                        <h3>Got Queries?</h3>
                        <ul>
                            <li>
                                <i className='fa fa-map'></i>
                                MEDIIT 307, Crossroad complex, Op. DKV college, Jamnagar, Gujarat- 361008, India
                            </li>
                            <li>
                                <i className='fa fa-phone-alt'></i>
                                <a href="tel:+91-9265283449">+91-9265283449</a>
                            </li>
                            <li>
                                <i className='fa fa-paper-plane'></i>
                                <a href="mailto:mediit.jamnagar07@gmail.com">mediit.jamnagar07@gmail.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className='legal'>
                <p>Copyright ©2023 MEDIIT All Rights Reserved | Designed by <a href="https://www.linkedin.com/in/shivampanchal0105/" target="_blank" rel="noreferrer" style={{ color: "white" }}>Shivam Panchal</a></p>
            </div>
        </>
    )
}

export default Footer

export const samplePaperData = [
    {
        title: "Class 8th",
        duration: "90 mins",
        pattern: "Objective",
        link: "/files/sample-papers/8th.pdf",
        filename: "Mast-8th.pdf"
    },
    {
        title: "Class 9th",
        duration: "90 mins",
        pattern: "Objective",
        link: "/files/sample-papers/9th.pdf",
        filename: "Mast-9th.pdf"
    },
    {
        title: "Class 10th",
        duration: "90 mins",
        pattern: "Objective",
        link: "/files/sample-papers/10th.pdf",
        filename: "Mast-10th.pdf"
    },
    {
        title: "Class 11th",
        duration: "90 mins",
        pattern: "Objective",
        link: "/files/sample-papers/11th.pdf",
        filename: "Mast-11th.pdf"
    },
    {
        title: "Class 12th",
        duration: "90 mins",
        pattern: "Objective",
        link: "/files/sample-papers/12th.pdf",
        filename: "Mast-12th.pdf"
    },
]
import React from 'react';

const NotFound = () => {
    return (
        <>
            <div className='heading'>
                <h1>OOPs...</h1>
                <h3>The Page you are looking for doesn't exist</h3>
            </div>
        </>
    );
}

export default NotFound
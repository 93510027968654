export const coursesData = [
    {
        id: 1,
        cover: "../images/courses-image/c1.png",
        coursesName1: "Engineering",
        link: '/engineering-courses'
    },
    {
        id: 2,
        cover: "../images/courses-image/c2.png",
        coursesName1: "Medical",
        link: '/medical-courses'
    },
    {
        id: 3,
        cover: "../images/courses-image/c3.png",
        coursesName1: "Commerce",
        link: 'commerce-courses'
    }
]
import React from 'react'
import './AboutUS.css'
import { aboutUSData } from './AboutUSData'
import LiveCount from './LiveCount'

const AboutUS = () => {
    return (
        <>
        <section className='aboutHome'>
            <div className='container flexSB'>
                <div className='left row image'>
                    <img src='./images/about.jpeg' alt='' />
                </div>
                <div className='right row'>
                    <div className='heading'>
                        <h3 className='blue-text'>Our Pedagogy</h3>
                        <h1 className='blue-text'>Why MEDIIT ?</h1>
                    </div>
                    <div className='items'>
                    {aboutUSData.map((val , index) => {
                        return (
                        <div key={index} className='item flexSB2 blue-color'>
                            <div className='img'>
                                <img src={val.cover} alt='' />
                            </div>
                            <div className='text'>
                                <h2 className='yellow-text'>{val.title}</h2>
                                <p>{val.desc}</p>
                            </div>
                        </div>
                        )
                    })}
                    </div>
                </div>
            </div>
        </section>
        <LiveCount />
    </>
    )
}

export default AboutUS
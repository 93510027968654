import './App.css';
import { BrowserRouter , Routes , Route } from 'react-router-dom'
import Main from './components/Home/Main'
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar'
import Team from './components/Team/Team';
import ContactUS from './components/ContactUS/ContactUS';
import EngineeringCourse from './components/EngineeringCourse/EngineeringCourse';
import MedicalCourse from './components/MedicalCourse/MedicalCourse'
import CommerceCourse from './components/CommerceCourse/CommerceCourse';
import Result from './components/Result/Result';
import Career from './components/Career/Career';
import Scholarship from './components/Scholarship/Scholarship';
import SamplePaper from './components/SamplePaper/SamplePaper';
import NotFound from './components/NotFound/NotFound';

function App() {
    return(
        <>
            <BrowserRouter>
                <Navbar />
                <Main />
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/team' element={<Team />} />
                    <Route exact path='/contact-us' element={<ContactUS />} />
                    <Route exact path='/engineering-courses' element={<EngineeringCourse />} />
                    <Route exact path='/medical-courses' element={<MedicalCourse />} />
                    <Route exact path='/commerce-courses' element={<CommerceCourse />} />
                    <Route exact path='/results' element={<Result />} />
                    <Route exact path='/careers' element={<Career />} />
                    <Route exact path='/scholarship' element={<Scholarship />} />
                    <Route exact path='/sample-papers' element={<SamplePaper />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;

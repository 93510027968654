import React from 'react'
import { Link } from 'react-router-dom'
import './Admission.css'
import { admissionData } from './AdmissonData'

const Admission = () => {
    return (
        <>
            <div id="admission" className='heading blue-text'>
                <h1>Our Admission Process</h1>
                <h3>We are gonna help you - Just read the information down below.</h3>
            </div>

            <section className='aboutHome'>
                <div className='container flexSB'>
                    <div className='right row'>
                        <div className='ad-info'>
                            <h3 className='blue-text'>The admission to our courses is on the basis of a scholarship cum admission test held in the months of Jan-March every year. These tests check the basic aptitude of a child along with his hold on the subjects he/she has studied so far. </h3>
                        </div>
                        <div className='items'>
                            {admissionData.map((val, index) => {
                                return (
                                    <div key={index} className='item flexSB blue-color'>
                                        <div className='text yellow-text'>
                                            <h2>{val.title}</h2>
                                            <p>{val.data}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='button'>
                            <Link to='/sample-papers'>
                                <button className='primary-btn'>
                                    Download Sample Papers<i className='fa fa-long-arrow-alt-right'></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='left row'>
                        <img src='./images/admission-process.jpeg' alt='' />
                    </div>
                </div>

            </section>
        </>
    )
}

export default Admission
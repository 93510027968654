import React from 'react';
import { Link } from 'react-router-dom';
import { samplePaperData } from './SamplePaperData';
import './SamplePaper.css';

const SamplePaper = () => {
    return (
        <>
            {/* <Link to='/files/sample-papers/8th.pdf' target='_blank' download>
                Download Sample Paper
            </Link> */}

            <div className='heading blue-text'>
                <h1>Sample Papers</h1>
                <h3>Sample Papers give children an idea of the type of questions to be asked in the entrance/scholarship test.</h3>
            </div>

            <section className='price padding'>
                <div className='container grid'>
                    {samplePaperData.map((val , index) => (
                        <div key={index} className='items blue-color'>
                            <h1>{val.title}</h1>
                            <p>
                                <ul>
                                    <li><strong>Time Duration</strong> : {val.duration}</li>
                                    <li><strong>Pattern</strong> : {val.pattern}</li>
                                </ul>
                            </p>
                            <Link to={val.link} target='_blank' download={val.filename}>
                                <button className='outline-btn'>Download Now</button>
                            </Link>
                            {/* <button className='outline-btn'>
                                <Link to={val.link} target='_blank' download={val.filename}>
                                    Download Now
                                </Link>
                            </button> */}
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
}

export default SamplePaper
import React, { useState } from 'react'
import './Career.css'
import axios from 'axios'

const Career = () => {
    const [state , setState] = useState({
        name: '',
        email: '',
        phonenumber: '',
        jobrole: ''
    });

    const [status , setStatus] = useState("Send Email");
    const [result , setResult] = useState(null);

    const sendMail = async (e) => {
        e.preventDefault();
        setStatus("Sending Email ...")
        
        axios
        .post('https://mediit-website-backend.vercel.app/send-email' , {...state})
        .then(response => {
            setResult(response.data)
            setState({
                name: '',
                email: '',
                phonenumber: '',
                jobrole: ''
            });
        })
        .catch(() => {
            setResult({
                success: false, 
                message: 'Something went wrong. Please try again later'
            });
        })

        setStatus("Send Email")
    }

    const onInputChange = (e) => {
        const {name , value} = e.target;

        setState({
            ...state,
            [name]: value
        });
    }

    return (
        <>
            <section className=''>
                <div className='heading blue-text'>
                    <h3>Join Us. Be You.</h3>
                    <h1>Careers Page</h1>
                </div>

                <div className='outer blue-color'>
                    <div className='careerHome yellow-text'>
                        <h2>We are committed to helping our teachers learn and grow.</h2>
                        <br />
                        <h4>We are always on the lookout for young and dynamic people who are passionate about their work. If you love challenges, want to grow rapidly, want to see your ideas being listened to and implemented, you need to be a part of our team! </h4>
                    </div>

                    <div className="flexSB2 careerMain">
                        <div className="">
                            <h1>Interested in Teaching or Management ?</h1>
                            <span>Send us your email address. Our team will get in touch with you soon. Looking forward to meet you soon :)</span>
                        </div>
                        <br />
                        <div className="">
                            <form onSubmit={sendMail}>
                                <div className='flexSB' style={{ margin: "10px 0px" }}>
                                    <input 
                                        type='text'
                                        name="name" 
                                        placeholder='Name' 
                                        value={state.name}
                                        onChange={onInputChange}
                                        required
                                    />
                                    <input 
                                        type='email' 
                                        name="email" 
                                        placeholder='Email ID' 
                                        value={state.email}
                                        onChange={onInputChange}
                                        required
                                    />
                                </div>
                                <input 
                                    type='text' 
                                    name="phonenumber" 
                                    placeholder='Phone Number' 
                                    value={state.phonenumber}
                                    onChange={onInputChange}
                                    required
                                />
                                <input 
                                    type='text' 
                                    name="jobrole" 
                                    placeholder='Job Role' 
                                    value={state.jobrole}
                                    onChange={onInputChange}
                                    required
                                />
                                <button className=''>{status}</button>
                            </form>
                            {
                                result && (
                                    <p className={`${result.success ? 'success' : 'error'}`}>
                                        {result.message}
                                    </p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career
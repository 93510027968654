import React , { useState } from 'react'
import { Link as LinkR } from 'react-router-dom'
import Head from './Head'
import './Navbar.css'
import { Link as LinkS } from "react-scroll";

const Navbar = () => {
    const [click , setClick] = useState(false);

    return (
        <>
            <Head />
            <header>
                <nav className='flexSB'>
                    <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
                        <li>
                            <LinkR to='/'>Home</LinkR>
                        </li>
                        <li>
                            <LinkS 
                                activeClass="active"
                                to="aboutus"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                About Us
                            </LinkS>
                        </li>
                        <li>
                            <LinkS 
                                activeClass="active"
                                to="courses"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Courses
                            </LinkS>
                        </li>
                        <li>
                            <LinkS 
                                activeClass="active"
                                to="testimonial"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Testimonial
                            </LinkS>
                        </li>
                        <li>
                            <LinkR to='/team'>Team</LinkR>
                        </li>
                        <li>
                            <LinkR to='/contact-us'>Contact Us</LinkR>
                        </li>
                        <li>
                            <LinkR to='/results'>Results</LinkR>
                        </li>
                        <li>
                            <LinkR to='/careers'>Careers</LinkR>
                        </li>
                        <li>
                            <LinkR to='/scholarship'>Scholarship</LinkR>
                        </li>
                    </ul>
                    <div className='start'>
                        <div className='button'>
                            <LinkR to='/contact-us'>
                                Want to Join US ?
                            </LinkR>
                        </div>
                    </div>
                    <button className='toggle' onClick={() => setClick(!click)}>
                        {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
                    </button>
                </nav>
            </header>
        </>
    )
}

export default Navbar
import React from 'react'
import './Home.css'

const Main = () => {
    return (
        <>
            <div className="outer-div">
                <div className="data-left">
                    <div className='main-data'>
                        <h1>Welcome to MEDIIT</h1>
                        <h3 style={{ color: "#fdd10b" }}>Crafting Your Passion</h3>
                        <p id="data">Synonymous to quality education and provides a learning environment that extends education beyond the confines of traditional classroom learning</p>
                    </div>
                </div>
                <div className="data-right">
                    <img alt='Front' src="./images/front-photo.png" />
                </div>
            </div>
        </>
    )
}

export default Main
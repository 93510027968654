export const admissionData = [
    {
        title: 'Syllabus',
        data: 'Mental Aptitude, Previous class Maths & Science'
    },
    {
        title: 'Pattern',
        data: 'Multiple choice questions with single answer correct'
    },
    {
        title: 'Parents Seminar',
        data: 'There is a parents seminar during the test in which we tell the parents about the latest examination trends, our teaching methodology etc and introduce our faculty.'
    },
    {
        title: 'Interview cum Counselling session',
        data: 'After the test results are declared, the child is called for a personalized session with our faculty members where we guide the student for the various career options and counsel him/her for the upcoming studies.'
    },
]
import React from 'react'
import { teamData } from './TeamData' 
import './Team.css'  

const Team = () => {
    return (
        <>
            <section className='team padding'>
                <div className='container grid2'>
                    {teamData.map((val) => (
                        <div key={val.id} className='items'>
                            <div className='img blue-color-round'>
                                <img src={val.cover} alt='' />
                                <div className='overlay'>
                                    <a href={val.facebookLink} target="_blank" rel="noreferrer"><i className='fab fa-facebook-f icon'></i></a>
                                    <a href={val.twitterLink} target="_blank" rel="noreferrer"><i className='fab fa-twitter icon'></i></a>
                                    <a href={val.instagramLink} target="_blank" rel="noreferrer"><i className='fab fa-instagram icon'></i></a>
                                    <a href={val.youtubeLink} target="_blank" rel="noreferrer"><i className='fab fa-youtube icon'></i></a>
                                    <a href={val.linkedinLink} target="_blank" rel="noreferrer"><i className='fab fa-linkedin icon'></i></a>
                                </div>
                            </div>
                            <div className='details '>
                                <h2 className='blue-text' style={{ fontSize: "25px" }}>{val.name}</h2>
                                <p style={{ color: "black", fontWeight: "600" , fontSize: "18px" }}>{val.work}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default Team
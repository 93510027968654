import React from 'react'
import { Link } from 'react-router-dom'
import './Courses.css'
import { coursesData } from './CoursesData'

const Courses = () => {
    return (
        <>
            <div id='courses' className="heading">
                <h1 className='blue-text'>Our Courses</h1>
            </div>

            <section className='coursesCard'>
                <div className='container grid2'>
                    {coursesData.map((val , index) => (
                        <div key={index} className='items blue-color'>
                            <div className='content flex'>
                                <div className='left'>
                                    <div className='img yellow-color'>
                                        <img src={val.cover} alt='' />
                                    </div>
                                </div>
                                <div className='text'>
                                    <h1 className='yellow-text'>{val.coursesName1} <br/> Courses</h1>
                                </div>
                            </div>
                            <Link to='/contact-us'><button className='outline-btn'>ENROLL NOW!</button></Link>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default Courses
import React from 'react'
import AboutUS from '../AboutUS/AboutUS'
import Admission from '../Admission/Admission'
import Card from '../Card/Card'
import Courses from '../Courses/Courses'
import Testimonial from '../Testimonial/Testimonial'
import './Home.css'

const Home = () => {
    return (
        <>
            <Card />
            <AboutUS />
            <Courses />
            <Testimonial />
            <Admission />
        </>
    )
}

export default Home
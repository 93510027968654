import React from 'react'
import FAQ from './FAQ'
import './Scholarship.css'

const Scholarship = () => {
    return (
        <>
            <section>
                <div className='heading blue-text'>
                    <h1>Scholarship Process</h1>
                    <h3>Direct Scholarship</h3>
                </div>

                <div className="limiter">
                    <div className="container-table100">
                        <div className="wrap-table100">
                            <div className="table">
                                <div className="row2 header">
                                    <div className="cell">
                                        For Class XI & XII
                                    </div>
                                    <div className="cell">
                                        Scholarship (at Tution Fees)
                                    </div>
                                </div>
                                <div className="row2">
                                    <div className="cell" data-title="For Class XI & XII">
                                        Army
                                    </div>
                                    <div className="cell" data-title="Scholarship (at Tution Fees)">
                                        10%
                                    </div>
                                </div>
                                <div className="row2">
                                    <div className="cell" data-title="For Class XI & XII">
                                        CBSE / Other State > 90 %
                                    </div>
                                    <div className="cell" data-title="Scholarship (at Tution Fees)">
                                        10%
                                    </div>
                                </div>
                                <div className="row2">
                                    <div className="cell" data-title="For Class XI & XII">
                                        KVPY Stage II Qualified
                                    </div>
                                    <div className="cell" data-title="Scholarship (at Tution Fees)">
                                        30%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='heading blue-text'>
                    <h3>MEDIIT - MAST Scholarship</h3>
                </div>

                <div className="limiter">
                    <div className="container-table100">
                        <div className="wrap-table100">
                            <div className="table">
                                <div className="row3 header">
                                    <div className="cell">
                                        Scholarship Name
                                    </div>
                                    <div className="cell">
                                        Percentage in MAST
                                    </div>
                                    <div className="cell">
                                        Scholarship (at Tution Fees)
                                    </div>
                                </div>
                                <div className="row3">
                                    <div className="cell" data-title="Scholarship Name">
                                        Silver
                                    </div>
                                    <div className="cell" data-title="Percentage in MAST">
                                        above 70%
                                    </div>
                                    <div className="cell" data-title="Scholarship (at Tution Fees)">
                                        20%
                                    </div>
                                </div>
                                <div className="row3">
                                    <div className="cell" data-title="Scholarship Name">
                                        Gold
                                    </div>
                                    <div className="cell" data-title="Percentage in MAST">
                                        above 80%
                                    </div>
                                    <div className="cell" data-title="Scholarship (at Tution Fees)">
                                        40%
                                    </div>
                                </div>
                                <div className="row3">
                                    <div className="cell" data-title="Scholarship Name">
                                        Platinum
                                    </div>
                                    <div className="cell" data-title="Percentage in MAST">
                                        above 95%
                                    </div>
                                    <div className="cell" data-title="Scholarship (at Tution Fees)">
                                        90%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FAQ />
        </>
    )
}

export default Scholarship
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EngineeringCourse from '../EngineeringCourse/EngineeringCourse'
import MedicalCourse from '../MedicalCourse/MedicalCourse'
import CommerceCourse from '../CommerceCourse/CommerceCourse'
import './Result.css'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Result = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ bgcolor: 'background.paper', width: 1/1 }}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        sx={{ backgroundColor: "#2b2e74" }}
                    >
                        <Tab label="Engineering Results" {...a11yProps(0)} />
                        <Tab label="Medical Results" {...a11yProps(1)} />
                        <Tab label="Commerce Results" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <EngineeringCourse />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <MedicalCourse />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <CommerceCourse />
                </TabPanel>
            </Box>
        </>
    );
}

export default Result;
import React , { useState } from 'react'
import './ContactUS.css'
import axios from 'axios'

const ContactUS = () => {
    const map = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14746.412140903103!2d70.060261!3d22.481547!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39576aa8589522d3%3A0x5f8252bd23ebe5a2!2sMEDIIT%20Jamnagar!5e0!3m2!1sen!2sus!4v1680943094667!5m2!1sen!2sus" width="100%" height="900px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'

    const [state , setState] = useState({
        name: '',
        email: '',
        subject: '',
        phonenumber: '',
        message: ''
    });
    const [status , setStatus] = useState("Send Message");
    const [result , setResult] = useState(null);

    const sendEmail = async (e) => {
        e.preventDefault();
        setStatus("Sending Message .....");

        axios
        .post('https://mediit-website-backend.vercel.app/send' , {...state})
        .then(response => {
            setResult(response.data);
            setState({
                name: '', 
                email: '', 
                subject: '', 
                phonenumber: '',
                message : ''
            });
        })
        .catch(() => {
            setResult({
                success: false, 
                message: 'Something went wrong. Please try again later'
            });
        })

        setStatus("Send Message");
    }

    const onInputChange = (e) => {
        const {name , value} = e.target;

        setState({
            ...state,
            [name]: value
        });
    }

    return (
        <>
            <section className='contacts padding'>
                <div className='container shadow'>
                    <div className='left currentLocation'>
                        <iframe title='currentLocation' src={map}></iframe>
                    </div>
                    <div className='right blue-color'>
                        <h1 className='yellow-text'>Contact us</h1>
                        <p className='padding3'>Tell us a little bit about yourself to get started. Come learn about the MEDIIT experience!</p>

                        <hr />

                        <div className='main-div grid2'>
                            <div className='box'>
                                <h4 className='yellow-text'>ADDRESS:</h4>
                                <p>MEDIIT 307, Crossroad complex, Op. DKV college, Jamnagar, Gujarat- 361008, India</p>
                            </div>
                            <div className='box'>
                                <h4 className='yellow-text'>EMAIL:</h4>
                                <p><a href="mailto:mediit.jamnagar07@gmail.com" style={{ color: "white" }}>mediit.jamnagar07@gmail.com</a></p>
                            </div>
                            <div className='box'>
                                <h4 className='yellow-text'>PHONE:</h4>
                                <p><a href="tel:+91-9265283449" style={{ color: "white" }}>+91-9265283449</a></p>
                            </div>
                        </div>

                        <hr />

                        <form className='padding3' onSubmit={sendEmail}>
                            <div className='flexSB'>
                                <input 
                                    type='text'
                                    name="name" 
                                    placeholder='Name' 
                                    value={state.name}
                                    onChange={onInputChange}
                                    required
                                />
                                <input 
                                    type='email' 
                                    name="email" 
                                    placeholder='Email ID' 
                                    value={state.email}
                                    onChange={onInputChange}
                                    required
                                />
                            </div>
                            <input 
                                type='text' 
                                name="subject" 
                                placeholder='Subject' 
                                value={state.subject}
                                onChange={onInputChange}
                                required
                            />
                            <input 
                                type='text' 
                                name="phonenumber" 
                                placeholder='Phone Number' 
                                value={state.phonenumber}
                                onChange={onInputChange}
                                required
                            />
                            <textarea 
                                name="message"
                                placeholder='Message'
                                value={state.message}
                                onChange={onInputChange}
                                required
                                cols='30'
                                rows='10'
                            />
                            <button className='secondary-btn'>{status}</button>
                        </form>
                        {
                            result && (
                                <p className={`${result.success ? 'success' : 'error'}`}>
                                    {result.message}
                                </p>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUS
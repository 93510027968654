export const teamData = [
    {
        cover: "./images/team-image/t1.png",
        name: "Pankaj Mangla",
        work: "B.Tech (RTU,KOTA) | 8+ Years of experience | Founder - MEDIIT",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t8.png",
        name: "Umar Ali",
        work: "B.Tech (M.E.) | 5+ Years oF experience | HOD Physics",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t4.png",
        name: "Satyendra Yadava",
        work: "M.Sc (Allahbad) | 8+ Years of experience | HOD Chemistry",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t3.png",
        name: "Anuj Vikash",
        work: "NEET (350 marks out of 360) | 24 Years of experience | HOD Biology BAMS (Pur.)",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t9.png",
        name: "Chandrashekhar Yadav",
        work: "B.Tech NIT | 3+ Years of experience | Asst. Maths Faculty",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t13.png",
        name: "Abhishek Prajapati",
        work: "NEET (575 marks out of 720) | 2+ Years of Experience | Asst. Physics BAMS (Pur.)",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t5.png",
        name: "Chintan Patel",
        work: "M.Sc (Organic Chem.) | 5+ Years of experience | Asst. Chemistry Faculty",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t11.png",
        name: "Urvi Joshi",
        work: "M.Sc, B.Ed, Phd | 5+ Years of experience | Asst. Biology Faculty",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t2.png",
        name: "Kalpi Hirpara",
        work: "B.ed, M.Sc (Maths) | 3+ Years of experience | Maths Faculty",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t6.png",
        name: "Parita Mehta",
        work: "B.Sc, B.ed | 4+ Years of experience | Science Faculty",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t12.png",
        name: "Jaydip Jadav",
        work: "B.Tech | 6+ Years of experience | Joint Managing Director",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t10.png",
        name: "Aditi Pandey",
        work: "MBA (Pur.) | 2+ Years oF experience | Management &Operation Head",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t7.png",
        name: "Shivam",
        work: "6+ Years of experience | Marketing Head",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
    {
        cover: "./images/team-image/t14.png",
        name: "Yuval Shah",
        work: "B.Tech (Pur.) | 2+ Years of Experience | Social Media Executive",
        facebookLink: "",
        twitterLink: "",
        instagramLink: "",
        youtubeLink: "",
        linkedinLink: ""
    },
]
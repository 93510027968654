export const cardData = [
    {
        id: 1,
        title: "Our Vision",
        desc: "Education is made for a purpose. The purpose is results in school & competitive exams, MEDIIT has vision to be the world leader in training students for competitions and preparing them for a bright career ahead. Not only we aim to make them excellent professionals in Engineering and Medical fields, but we prepare them for the challenges that they face in life. Above all we aim to prepare students to be responsible and progressive citizens of the country.",
        cover: "../images/card-image/b1.jpeg",
    },
    {
        id: 2,
        title: "History",
        desc: "MEDIIT was established in 2015 with a handful of students by Pankaj Mangla Sir. A few sessions old, the institute scaled new heights of success by providing coaching of Engineering and Medical entrance exam preparation. New milestones were set each year and the institute achieved a remarkable landmark with many selections. Every year, hundreds of our students realize their dreams of getting admission into top Engineering & Medical colleges.",
        cover: "../images/card-image/b2.jpeg",
    },
    {
        id: 3,
        title: "Our Mission",
        desc: "Redefining quality learning through innovation and farsightedness has been a hallmark of MEDIIT DNA. Our way of creating trends and benchmarks of learning and testing has seen another acknowledgement today by the changed new JEE / NEET / GUJ-CET / COMMERCE pattern. Meritorious and underprivileged are our national assets and MEDIIT stands committed to them.",
        cover: "../images/card-image/b3.jpeg",
    },
]
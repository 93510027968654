export const aboutUSData = [
    {
        id: 1,
        cover: "https://img.icons8.com/ios/80/000000/diploma.png",
        title: "Highly Qualified Teachers",
        desc: "Team comprising of Ex-IIT Professors, IIT-ians and Doctorates, with regular feedback system, Parent Teacher meeting and detailed analysis report after every major test.",
    },
    {
        id: 1,
        cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
        title: "Interactive and Innovative Teaching",
        desc: "Applying various methodology to suit every individual's need, with small batch size and lowest student-teacher ratio focusing on holistic development.",
    },
    {
        id: 1,
        cover: "https://img.icons8.com/ios/80/000000/athlete.png",
        title: "Precise and Exhaustive Training",
        desc: "Exhaustive study material, each being precise for competitive exams as well as school exams. Rigorous daily practice problems (DPPs) and topic-wise tests.",
    }
]

export const liveCountData = [
    {
        cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
        data: "3,000",
        title: "SUCCESS STORIES",
    },

    {
        cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
        data: "50",
        title: "TRUSTED TUTORS",
    },
    
    {
        cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
        data: "100%",
        title: "UPTO SCHOLARSHIP",
    }
]
export const testimonialData = [
    {
        id: 1,
        name: "Priyank Godhat",
        post: "BITS Pilani",
        desc: "Pankaj Sir is the true concomitance of teacher and friend, who would help us communicate with you better and to dispel our misunderstandings about concepts, he inspired us to work harder, he explain things that students find it hard to understand and simplify them, he gives encouraging comments and leaves a positive impact on as tudent.",
        cover: "./images/testimonial-image/t1.jpeg",
    },
    {
        id: 2,
        name: "Akshay Busa",
        post: "SVNIT Surat",
        desc: "I attribute my sucess to MEDIIT especially to Pankaj Sir who personally quicked me to achieve this sgoal. I was a student of MEDIIT JEE Mains & Advanced - 2016 in general category. i also got 94.46% ni my state board exam. I am thankful to my parents and the faculites of MEDIIT.",
        cover: "./images/testimonial-image/t2.png",
    },
    {
        id: 3,
        name: "Anjali Viramgama",
        post: "University of Maryland, College Park (USA)",
        desc: "MEDIIT have given the most memorable days for shaping my career and personality. I always felt as a member of this family that has been working constantly, dedicatedly towards each student. Pankaj Sir is the most genuine person and a dedicated tutor past my imaginations. His and the MEDIIT faculty's hard-work and determination always inspired me a lot.",
        cover: "./images/testimonial-image/t3.jpeg",
    },
    {
        id: 4,
        name: "Hemish Gangajaliya",
        post: "SVNIT Surat",
        desc: "I attribute my success in JEE Mains & Advanced to Pankaj Sir. He gave us ample individual attention and developed a comfortable atmosphere inside the classroom, His way of teaching was very wonderful. He was available 24/7 and didn't hesitate to clear our doubts even at odd hours.",
        cover: "./images/testimonial-image/t4.jpeg",
    },
    {
        id: 5,
        name: "Vaibhav Kotecha",
        post: "Arizona State University (USA)",
        desc: "MEDIT has played a very vital role inmy life career. I really appreciate the dedication, hardwork and perseverance by all the members in achieving the desired goals. Pankaj Sir, the director of MEDIIT has been a god-father to all his students and by his friendly and professional gesture.",
        cover: "./images/testimonial-image/t5.jpeg",
    },
    {
        id: 6,
        name: "Meet Rajkotiya",
        post: "Nirma University",
        desc: "It was an amazing experience studying in MEDIIT. It is a great institute for preparation of competitive exams along with respective boards. Faculties here are highly qualified and friendly. They have helped me build my concepts and have solved all my doubts. The journey at MEDIIT was really wonderful. ",
        cover: "./images/testimonial-image/t6.jpeg",
    },
    {
        id: 7,
        name: "Akarsh Jain",
        post: "DAIICT",
        desc: "During my early days of preparation for JEE, I really struggled with maths but the way Pankaj Sir understood my problems with the subject personally and helped me identify those, it became a strong subject for me. His method of teaching is tailor made for competitive exams and he gives you the confidence to tackle any mathematical problem with ease.",
        cover: "./images/testimonial-image/t7.jpeg",
    }
]
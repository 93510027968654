import React from 'react'
import './Card.css'
import { cardData } from './CardData'

const Card = () => {
    return (
        <>
            <section id="aboutus" className='card padding2'>
                <div className='heading'>
                    <h3 className='blue-text'>MEDIIT</h3>
                    <h1 className='blue-text'>Who Are We?</h1>
                </div>
                <div className='container grid2'>
                    {cardData.map((val) => (
                        <div key={val.id} className='items blue-color'>
                            <div>
                                <img src={val.cover} alt='' />
                            </div>
                            <div className='text'>
                                {/* <div className='admin flexSB'>
                                    <span>
                                        <i className='fa fa-user'></i>
                                        <label htmlFor=''>{val.type}</label>
                                    </span>
                                    <span>
                                        <i className='fa fa-calendar-alt'></i>
                                        <label htmlFor=''>{val.date}</label>
                                    </span>
                                    <span>
                                        <i className='fa fa-comments'></i>
                                        <label htmlFor=''>{val.com}</label>
                                    </span>
                                </div> */}
                                <h1 className='card-title yellow-text'>{val.title}</h1>
                                <p>{val.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default Card
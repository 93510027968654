import React, { useState } from 'react'
import { faqData } from './FAQData';
import './FAQ.css';

const FAQ = () => {
    const [click, setClick] = useState(false);

    const toggle = (index) => {
        if (click === index) {
            return setClick(null);
        }
        setClick(index);
    }

    return (
        <>
            <div className='heading blue-text'>
                <h3>FAQs</h3>
                <h1>Frequently Asked Questions</h1>
            </div>

            <section className='faq'>
                <div className='container'>
                    {faqData.map((val, index) => (
                        <div key={index} className='box'>
                            <button className='accordion' onClick={() => toggle(index)} key={index}>
                                <h2>{val.title}</h2>
                                <span>{click === index ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-right'></i>}</span>
                            </button>
                            {click === index ? (
                                <div className='text'>
                                    <p>{val.desc}</p>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default FAQ
import React from 'react'
import './EngineeringCourse.css'
import { resultData } from './ResultData'

const EngineeringCourse = () => {
    return (
        <>
            <section className='fieldHome'>
                <div className='heading blue-text'>
                    <h3>PREVIOUS</h3>
                    <h1>Engineering Results</h1>
                </div>

                <hr />
                {resultData.map((val , index) => (
                    <>
                        <div key={index} className='fieldDiv blue-text' >
                            <h2>{val.title}</h2>
                            <div>
                                <img src={val.cover} alt='' />
                            </div>
                        </div>
                        <hr />
                    </>
                ))}
            </section>
        </>
    )
}

export default EngineeringCourse    
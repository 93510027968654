import React from 'react'
import { testimonialData } from './TestimonialData'
import './Testimonial.css'

const Testimonial = () => {
    return (
        <>
            <section className='testimonal padding'>
                <div className='container'>
                    <div id="testimonial" className='heading blue-text'>
                        <h3>TESTIMONIAL</h3>
                        <h1>Our Successful Students</h1>
                    </div>

                    <div className='content grid2'>
                        {testimonialData.map((val , index) => (
                            <div key={index} className='items blue-color'>
                                <div className='box flex'>
                                    <div className='img'>
                                        <img src={val.cover} alt='' />
                                        <i className='fa fa-quote-left icon'></i>
                                    </div>
                                    <div className='name'>
                                        <h2 className='yellow-text'>{val.name}</h2>
                                        <span>{val.post}</span>
                                    </div>
                                </div>
                                <p>{val.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial